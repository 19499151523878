<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="9">
                <v-card flat class="mr-3" :loading="loading">
                        <v-card-title>
                            {{ pageTitle }}
                            <v-spacer></v-spacer>
                            <v-btn depressed small color="warning" v-if="isEdit" @click="resetPwdDialog = true">Reset pwd</v-btn>
                        </v-card-title>
                        <v-card-subtitle>{{ pageSubtitle }}</v-card-subtitle>
                        <v-card-text v-if="user != null">
                            <v-form ref="form">
                                <v-row>
                                    <v-col cols="6">
                                        <div class="subheading grey--text">
                                            General Information
                                        </div>

                                        <v-row no-gutters>
                                            <v-col cols="12" md="6">
                                                <v-text-field v-model="user.firstName" label="First name" :rules="[ rules.required ]"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-text-field v-model="user.lastName" label="Last name" :rules="[ rules.required ]"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-text-field v-model="user.mobilePhone" label="Mobile phone" type="tel" v-mask="'(###) ###-####'" :rules="[ rules.isPhone ]"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-text-field v-model="user.workPhone" label="Work phone" type="tel" v-mask="'(###) ###-####'" :rules="[ rules.isPhone ]"></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-text-field v-model="user.email" label="Email" :rules="[ rules.required, rules.isEmail ]"></v-text-field>
                                            </v-col>
                                        </v-row>

                                        <div class="subheading grey--text mt-5">
                                            Address
                                        </div>

                                        <v-row no-gutters>
                                            <v-col cols="12">
                                                <v-text-field v-model="user.addressLine1" label="Address line 1"></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-text-field v-model="user.addressLine2" label="Address line 2"></v-text-field>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-text-field v-model="user.addressCity" label="City" type="email"></v-text-field>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-text-field v-model="user.addressState" label="State" v-mask="'AA'"></v-text-field>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-text-field v-model="user.addressZipCode" label="Zip code" v-mask="'#####'"></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>

                                    <v-col cols="6">
                                        <div class="subheading grey--text">
                                            Work Information
                                        </div>

                                        <v-row no-gutters>
                                            <v-text-field v-model="user.wintuxClerkNum" label="Wintux Clerk Num" :rules="[ rules.required ]"></v-text-field>
                                            <v-select v-model="user.baseStoreId" label="Base Store" :items="stores" item-value="id" item-text="name" :rules="[ rules.required ]"></v-select>
                                            <v-select v-model="user.departmentId" label="Department" :items="departments" item-value="id" item-text="name" :rules="[ rules.required ]"></v-select>
                                            <v-select v-model="user.positionId" label="Position" :items="positions" item-value="id" item-text="name" :rules="[ rules.required ]"></v-select>
                                            <v-text-field v-model="user.securityCode" label="Security code" type="password" :rules="[ rules.required, rules.minLength, rules.maxLength ]"></v-text-field>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-switch v-model="user.eligibleForCommission" label="Eligible for Commission" persistent-hint dense
                                            hint="When enabled the user will accomulate commisions whenever he/she create an order"></v-switch>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-switch v-model="user.active" label="Active" dense></v-switch>
                                    </v-col>
                                </v-row>
                            </v-form>

                            <div class="subheading grey--text mt-5">
                                Privileges
                            </div>

                            <v-simple-table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Privilege</th>
                                        <th>Meta</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td width="50">
                                            <v-checkbox :value="privileges['superAdmin'] !== undefined" @change="updatePrivilege( 'superAdmin', $event )"></v-checkbox>
                                        </td>
                                        <td>Super admin</td>
                                        <td></td>
                                        <td>Give access to everything</td>
                                    </tr>

                                    <tr class="category">
                                        <td colspan="4">Rental app</td>
                                    </tr>

                                    <tr>
                                        <td width="50">
                                            <v-checkbox :value="privileges['kioskAccess'] !== undefined" @change="updatePrivilege( 'kioskAccess', $event )"></v-checkbox>
                                        </td>
                                        <td>Kiosk access</td>
                                        <td></td>
                                        <td>Give access to the rental app</td>
                                    </tr>
                                    <tr :class="privileges['kioskAccess'] === undefined ? 'disabled' : ''">
                                        <td width="50">
                                            <v-checkbox :value="privileges['noCharge'] !== undefined" @change="updatePrivilege( 'noCharge', $event )" :disabled="privileges['kioskAccess'] === undefined"></v-checkbox>
                                        </td>
                                        <td>No charge</td>
                                        <td></td>
                                        <td>Allow this user to apply no charges in the rental app</td>
                                    </tr>
                                    <tr :class="privileges['kioskAccess'] === undefined ? 'disabled' : ''">
                                        <td width="50">
                                            <v-checkbox :value="privileges['removeBan'] !== undefined" @change="updatePrivilege( 'removeBan', $event )" :disabled="privileges['kioskAccess'] === undefined"></v-checkbox>
                                        </td>
                                        <td>Remove ban</td>
                                        <td></td>
                                        <td>Allow this user to remove customers from the ban list</td>
                                    </tr>
                                    <tr :class="privileges['kioskAccess'] === undefined ? 'disabled' : ''">
                                        <td width="50">
                                            <v-checkbox :value="privileges['trainingMode'] !== undefined" @change="updatePrivilege( 'trainingMode', $event )" :disabled="privileges['kioskAccess'] === undefined"></v-checkbox>
                                        </td>
                                        <td>Training mode</td>
                                        <td></td>
                                        <td>User will be able to create reservations but will need the approval of the store manager before creating or updating an order</td>
                                    </tr>

                                    <tr class="category">
                                        <td colspan="4">Admin panel</td>
                                    </tr>

                                    <tr>
                                        <td width="50">
                                            <v-checkbox :value="privileges['adminAccess'] !== undefined" @change="updatePrivilege( 'adminAccess', $event )"></v-checkbox>
                                        </td>
                                        <td>Admin panel</td>
                                        <td></td>
                                        <td>Give access to the admin panel</td>
                                    </tr>
                                    <tr :class="privileges['adminAccess'] === undefined ? 'disabled' : ''">
                                        <td width="50">
                                            <v-checkbox :value="privileges['reportAccess'] !== undefined" @change="updatePrivilege( 'reportAccess', $event )" :disabled="privileges['adminAccess'] === undefined"></v-checkbox>
                                        </td>
                                        <td>Report access</td>
                                        <td>
                                            <v-select :value="privileges['reportAccess']" :items="reportStores" item-text="name" item-value="id" 
                                                outlined hide-details label="Reports store" :disabled="privileges['adminAccess'] === undefined || privileges['reportAccess'] === undefined" 
                                                dense hint="Give access to a specific store. If empty, the user will have access to all stores"
                                                @change="(val) => { privileges['reportAccess'] = val }"></v-select>
                                        </td>
                                        <td>Give access to the admin reports</td>
                                    </tr>
                                    <tr :class="privileges['adminAccess'] === undefined ? 'disabled' : ''">
                                        <td width="50">
                                            <v-checkbox :value="privileges['userManagement'] !== undefined" @change="updatePrivilege( 'userManagement', $event )" :disabled="privileges['adminAccess'] === undefined"></v-checkbox>
                                        </td>
                                        <td>User management</td>
                                        <td></td>
                                        <td>Give access to manage other user accounts</td>
                                    </tr>
                                    <tr :class="privileges['adminAccess'] === undefined ? 'disabled' : ''">
                                        <td width="50">
                                            <v-checkbox :value="privileges['storeManagement'] !== undefined" @change="updatePrivilege( 'storeManagement', $event )" :disabled="privileges['adminAccess'] === undefined"></v-checkbox>
                                        </td>
                                        <td>Store management</td>
                                        <td></td>
                                        <td>Give access to manage stores</td>
                                    </tr>
                                    <tr :class="privileges['adminAccess'] === undefined ? 'disabled' : ''">
                                        <td width="50">
                                            <v-checkbox :value="privileges['productManagement'] !== undefined" @change="updatePrivilege( 'productManagement', $event )" :disabled="privileges['adminAccess'] === undefined"></v-checkbox>
                                        </td>
                                        <td>Product management</td>
                                        <td></td>
                                        <td>Give access to manage products</td>
                                    </tr>
                                    <tr :class="privileges['adminAccess'] === undefined ? 'disabled' : ''">
                                        <td width="50">
                                            <v-checkbox :value="privileges['promoManagement'] !== undefined" @change="updatePrivilege( 'promoManagement', $event )" :disabled="privileges['adminAccess'] === undefined"></v-checkbox>
                                        </td>
                                        <td>Promo management</td>
                                        <td></td>
                                        <td>Give access to manage promo codes</td>
                                    </tr>
                                    <tr :class="privileges['adminAccess'] === undefined ? 'disabled' : ''">
                                        <td width="50">
                                            <v-checkbox :value="privileges['openToBuy'] !== undefined" @change="updatePrivilege( 'openToBuy', $event )" :disabled="privileges['adminAccess'] === undefined"></v-checkbox>
                                        </td>
                                        <td>Open to buy</td>
                                        <td></td>
                                        <td>Give access to configure the "Open to Buy" report</td>
                                    </tr>

                                    <tr class="category">
                                        <td colspan="4">Central app</td>
                                    </tr>

                                    <tr>
                                        <td width="50">
                                            <v-checkbox :value="privileges['central'] !== undefined" @change="updatePrivilege( 'central', $event )"></v-checkbox>
                                        </td>
                                        <td>Central panel</td>
                                        <td></td>
                                        <td>Give access to the central app</td>
                                    </tr>
                                    <tr :class="privileges['central'] === undefined ? 'disabled' : ''">
                                        <td width="50">
                                            <v-checkbox :value="privileges['centralAdmin'] !== undefined" @change="updatePrivilege( 'centralAdmin', $event )" :disabled="privileges['central'] === undefined"></v-checkbox>
                                        </td>
                                        <td>Central administrator</td>
                                        <td></td>
                                        <td>Give access to the everything inside Central app</td>
                                    </tr>
                                    <tr :class="privileges['central'] === undefined ? 'disabled' : ''">
                                        <td width="50">
                                            <v-checkbox :value="privileges['dashboards'] !== undefined" @change="updatePrivilege( 'dashboards', $event )" :disabled="privileges['central'] === undefined"></v-checkbox>
                                        </td>
                                        <td>Dashboards</td>
                                        <td></td>
                                        <td>Give access to the dashboards section</td>
                                    </tr>
                                    <tr :class="privileges['central'] === undefined ? 'disabled' : ''">
                                        <td width="50">
                                            <v-checkbox :value="privileges['stationProcessing'] !== undefined" @change="updatePrivilege( 'stationProcessing', $event )" :disabled="privileges['central'] === undefined"></v-checkbox>
                                        </td>
                                        <td>Processing station</td>
                                        <td></td>
                                        <td>Give access to the processing station</td>
                                    </tr>
                                    <tr :class="privileges['central'] === undefined ? 'disabled' : ''">
                                        <td width="50">
                                            <v-checkbox :value="privileges['stationQC'] !== undefined" @change="updatePrivilege( 'stationQC', $event )" :disabled="privileges['central'] === undefined"></v-checkbox>
                                        </td>
                                        <td>QC processing station</td>
                                        <td></td>
                                        <td>Give access to the QC processing station</td>
                                    </tr>
                                    <tr :class="privileges['central'] === undefined ? 'disabled' : ''">
                                        <td width="50">
                                            <v-checkbox :value="privileges['stationTailoring'] !== undefined" @change="updatePrivilege( 'stationTailoring', $event )" :disabled="privileges['central'] === undefined"></v-checkbox>
                                        </td>
                                        <td>Tailoring station</td>
                                        <td></td>
                                        <td>Give access to the tailoring station</td>
                                    </tr>
                                    <tr :class="privileges['central'] === undefined ? 'disabled' : ''">
                                        <td width="50">
                                            <v-checkbox :value="privileges['stationWaitingFI'] !== undefined" @change="updatePrivilege( 'stationWaitingFI', $event )" :disabled="privileges['central'] === undefined"></v-checkbox>
                                        </td>
                                        <td>Waiting FI station</td>
                                        <td></td>
                                        <td>Give access to the Waiting for final inspection station</td>
                                    </tr>
                                    <tr :class="privileges['central'] === undefined ? 'disabled' : ''">
                                        <td width="50">
                                            <v-checkbox :value="privileges['stationFinalInspection'] !== undefined" @change="updatePrivilege( 'stationFinalInspection', $event )" :disabled="privileges['central'] === undefined"></v-checkbox>
                                        </td>
                                        <td>Final inspection station</td>
                                        <td></td>
                                        <td>Give access to the final inspection station</td>
                                    </tr>
                                    <tr :class="privileges['central'] === undefined ? 'disabled' : ''">
                                        <td width="50">
                                            <v-checkbox :value="privileges['stationTruckLine'] !== undefined" @change="updatePrivilege( 'stationTruckLine', $event )" :disabled="privileges['central'] === undefined"></v-checkbox>
                                        </td>
                                        <td>Truck line station</td>
                                        <td></td>
                                        <td>Give access to the truck line station</td>
                                    </tr>
                                    <tr :class="privileges['central'] === undefined ? 'disabled' : ''">
                                        <td width="50">
                                            <v-checkbox :value="privileges['stationCheckIn'] !== undefined" @change="updatePrivilege( 'stationCheckIn', $event )" :disabled="privileges['central'] === undefined"></v-checkbox>
                                        </td>
                                        <td>Check in station</td>
                                        <td></td>
                                        <td>Give access to the check in station</td>
                                    </tr>
                                    <tr :class="privileges['central'] === undefined ? 'disabled' : ''">
                                        <td width="50">
                                            <v-checkbox :value="privileges['stationInStorage'] !== undefined" @change="updatePrivilege( 'stationInStorage', $event )" :disabled="privileges['central'] === undefined"></v-checkbox>
                                        </td>
                                        <td>In storage station</td>
                                        <td></td>
                                        <td>Give access to the in storage station</td>
                                    </tr>
                                    <tr :class="privileges['central'] === undefined ? 'disabled' : ''">
                                        <td width="50">
                                            <v-checkbox :value="privileges['stationSpotting'] !== undefined" @change="updatePrivilege( 'stationSpotting', $event )" :disabled="privileges['central'] === undefined"></v-checkbox>
                                        </td>
                                        <td>Spotting station</td>
                                        <td></td>
                                        <td>Give access to the spotting station</td>
                                    </tr>
                                    <tr :class="privileges['central'] === undefined ? 'disabled' : ''">
                                        <td width="50">
                                            <v-checkbox :value="privileges['createItem'] !== undefined" @change="updatePrivilege( 'createItem', $event )" :disabled="privileges['central'] === undefined"></v-checkbox>
                                        </td>
                                        <td>Item creation</td>
                                        <td></td>
                                        <td>Give access to add new items to the inventory</td>
                                    </tr>
                                    <tr :class="privileges['central'] === undefined ? 'disabled' : ''">
                                        <td width="50">
                                            <v-checkbox :value="privileges['manageInventory'] !== undefined" @change="updatePrivilege( 'manageInventory', $event )" :disabled="privileges['central'] === undefined"></v-checkbox>
                                        </td>
                                        <td>Inventory management</td>
                                        <td></td>
                                        <td>Give access to make changes to the inventory</td>
                                    </tr>
                                    <tr :class="privileges['central'] === undefined ? 'disabled' : ''">
                                        <td width="50">
                                            <v-checkbox :value="privileges['verifyInventory'] !== undefined" @change="updatePrivilege( 'verifyInventory', $event )" :disabled="privileges['central'] === undefined"></v-checkbox>
                                        </td>
                                        <td>Inventory verification</td>
                                        <td></td>
                                        <td>Give access to verify the current inventory</td>
                                    </tr>
                                </tbody>
                            </v-simple-table>

                            <div class="text-right mt-7">
                                <v-btn depressed color="grey darken-4" class="white--text" @click="save" :loading="saving">Save</v-btn>
                            </div>
                        </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="3">
                <v-card flat>
                    <v-card-title class="headline font-weight-light grey--text text--darken-4">History</v-card-title>
                    <v-card-text class="px-0">
                        <history obj-type="user" :id="userId"  :showForm="false" ref="history" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="resetPwdDialog" width="500">
            <v-card>
                <v-card-title>Warning</v-card-title>
                <v-card-text>
                    <p>Are you sure you want to reset the admin panel password for this user?</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="resetPwdDialog = false" style="text-transform:none">Cancel</v-btn>
                    <v-btn depressed color="error" class="white--text" @click="resetPwd" style="text-transform:none" :loading="reseting">Yes</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import History from '@/components/History.vue';
import { API } from '@/inc/api';
import { _st } from '@/softech';
import { mask } from 'vue-the-mask';

export default {
    props: {
        userId: { type: [ Number, String ], default: null }
    },
    data: () => ({
        user                : null,
        loading             : false,
        saving              : false,
        resetPwdDialog      : false,
        reseting            : false,

        departments         : [],
        positions           : [],

        privileges          : {},

        rules               : {
            required        : v => !!v || 'required',
            isEmail         : v => _st.isEmail( v ) || 'must be a valid email address',
            isPhone         : v => ( _st.isEmpty( v ) || _st.isPhone( v ) ) || 'must be a valid phone number',
            isInteger       : v => _st.isInteger( v ) && v >= 0 || 'must be a positive integer value',
            isTime          : v => !_st.isNUE( v ) && v.split(':').length >= 2 || 'must be a valid time',
            minLength       : v => v != null && v.length >= 4 || 'code must be at least 4 characters long',
            maxLength       : v => v != null && v.length <= 15 || 'code maximum length is 15'
        }
    }),
    computed: {
        ...mapGetters({
            stores              : 'stores/activeStores',
            storesNeedRefresh   : 'stores/needRefresh'
        }),
        pageTitle() {
            if( _st.isNUE( this.userId ) || this.userId == 'new' ) {
                return 'Create new user';
            }
            else if( _st.isNUE( this.user ) ) {
                return 'Edit user';
            }
            else {
                return this.user.fullName;
            }
        },
        pageSubtitle() {
            if( _st.isNUE( this.userId ) ) {
                return 'Create a new user to be used within the LFA Rental App';
            }
            else {
                return 'Edit all the details of the user';
            }
        },
        reportStores() {
            return [{ id: '', name: 'All' }].concat( this.stores );
        },
        isEdit() {
            return !_st.isNUE( this.userId ) && this.userId !== 'new';
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions({
           saveUser     : 'users/saveUser',
           loadStores   : 'stores/loadStores'
        }),
        async init() {
            try {
                this.cleanUserObject();
                this.loadObjects();

                if( _st.isNUE( this.userId ) || this.userId == 'new' ) {
                    return;
                }

                let api = new API();

                this.loading = true;
                let res = await api.get(`/admin/user/${this.userId}`);
                this.loading = false;

                if( !res.data.status ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return false;
                }

                if( _st.isNUE( res.data.data.id ) ) {
                    this.$router.push('/404');
                    return;
                }

                this.user = res.data.data;
                this.user.mobilePhone = _st.toPhoneNumber( this.user.mobilePhone );
                this.user.workPhone = _st.toPhoneNumber( this.user.workPhone );

                
                for( const key in this.user.privileges ) {
                    if( key == 'reportAccess' && !_st.isEmpty( this.user.privileges[key] ) ) {
                        Vue.set( this.privileges, key, parseInt( this.user.privileges[key] ) );
                    }
                    else
                        Vue.set( this.privileges, key, this.user.privileges[key] );
                }

                this.$refs.history.getHistory();
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage( 'ERROR: ' + error, 'error' );
            }
        },
        async loadObjects() {
            let api = new API() ;

            try {
                this.loading = true;
                if( this.stores.length == 0 || this.storeNeedRefresh )
                    await this.loadStores();
                
                let resDept = await api.get('/admin/departments');
                let resPos = await api.get('/admin/positions');
                this.loading = false;

                if( !resDept.data.status ) {
                    this.mxShowMessage( resDept.data.message, resDept.data.error ? 'error' : 'warning' );
                    return false;
                }
                if( !resPos.data.status ) {
                    this.mxShowMessage( resPos.data.message, resPos.data.error ? 'error' : 'warning' );
                    return false;
                }

                this.departments = resDept.data.data;
                this.positions = resPos.data.data;
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage( 'ERROR: ' + error, 'error' );
            }
        },
        updatePrivilege( privilege, val ) {
            if( val === true ) {
                Vue.set( this.privileges, privilege, '' );
                return;
            }

            Vue.delete( this.privileges, privilege );
        },
        async save() {
            if( !this.$refs.form.validate() )
                return;

            try {
                this.user.privileges = this.privileges;

                console.log(this.user.privileges);

                this.saving = true;
                let res = await this.saveUser( this.user );
                this.saving = false;

                if( !res.status ) {
                    this.mxShowMessage( res.message, res.error ? 'error' : 'warning' );
                    return false;
                }

                if( _st.isNUE( this.userId ) || _st.isNUE( this.user.id ) ) {
                    this.$router.push(`/users/${res.data}`);
                    return;
                }

                this.user = res.data;
                this.user.mobilePhone = this.formatPhoneNum( this.user.mobilePhone );
                this.user.workPhone = this.formatPhoneNum( this.user.workPhone );
                this.user.departmentId = this.user.departmentId.toString();
                this.user.positionId = this.user.positionId.toString();
                this.user.baseStoreId = this.user.baseStoreId.toString();

                for( const key in this.user.privileges ) {
                    if( key == 'reportAccess' && !_st.isEmpty( this.user.privileges[key] ) ) {
                        Vue.set( this.privileges, key, parseInt( this.user.privileges[key] ) );
                    }
                    else
                        Vue.set( this.privileges, key, this.user.privileges[key] );
                }

                this.mxShowMessage( 'User saved', 'success' );
            }
            catch(error) {
                this.saving = false;
                this.mxShowMessage( 'ERROR: ' + error, 'error' );
            }
        },
        async resetPwd() {
            let api = new API();

            try {
                this.reseting = true;
                let res = await api.post('/admin/user/reset-pwd', { userId: this.userId });
                this.reseting = false;

                if( res.data.status !== true ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }

                this.resetPwdDialog = false;
                this.mxShowMessage( 'Password has been reset', 'success' );
            }
            catch(error) {
                this.reseting = false;
                this.mxShowMessage( error, 'error' );
            }
        },
        cleanUserObject() {
            this.user = {
                id: 0,
                firstName: '',
                lastName: '',
                fullName: '',
                addressLine1: '',
                addressLine2: '',
                addressCity: '',
                addressState: '',
                addressZipCode: '',
                wintuxClerkNum: '',
                securityCode: '',
                workPhone: '',
                mobilePhone: '',
                departmentId: 0,
                eligibleForCommission: true,
                positionId: 0,
                email: '',
                baseStoreId: 0,
                active: true,
                dateCreated: null,
                lastLogin: null,
                rmhPassword: '',
                lastAdminLogin: null,
                privileges: {}
            }
        }
    },
    components: { History },
    directives: { mask }
}
</script>

<style lang="scss" scoped>
.disabled {
    color: #9e9e9e;
}
.category td {
    background-color: #eee;
    height: 30px !important;
    color: #707070;
    font-size: 13px !important;
}
</style>